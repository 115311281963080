/* eslint-disable import/prefer-default-export */
export const AVAILABLE_PAGES = [
  { key: "home", name: "Home" },
  { key: "whitelabelHome", name: "Whitelabel Home" },
  { key: "allUser", name: "All User" },
  { key: "noAccountUser", name: "User with No Bank Account" },
  { key: "tagmangoCreators", name: "Tagmango Creators" },
  { key: "freedomCreators", name: "Freedom Creators" },
  { key: "enterpriseCreators", name: "Enterprise Creators" },
  { key: "dashboardBanner", name: "Dashboard Banner" },
  { key: "onboardingDetails", name: "Onboarding Details" },
  { key: "tierOrders", name: "Tier Orders" },
  { key: "widthdrawalsSection", name: "Withdrawal Section" },
  { key: "creatorPayables", name: "Creator Payables" },
  { key: "indianWalletWithdrawals", name: "Wallet Withdrawals (IND)" },
  { key: "internationalWalletWithdrawals", name: "Wallet Withdrawals (INTL)" },
  { key: "taxReports", name: "Tax Reports" },
  { key: "upcomingMangos", name: "Upcoming Mangos" },
  { key: "orderDetails", name: "Order Details" },
  { key: "refunds", name: "Refunds" },
  // { key: "refundRequests", name: "Refund Requests" },
  { key: "creatorEarnings", name: "Creators Earnings" },
  { key: "affiliateEarnings", name: "Affiliate Earnings" },
  { key: "userDetailsList", name: "User Details List" },
  { key: "pushNotifications", name: "Push Notifications" },
  { key: "couponSection", name: "Coupon Section" },
  // { key: "creatorAccess", name: "Creator Access" },
  // { key: "certificatesSection", name: "Certificates Section" },
  { key: "emailSection", name: "Email Section" },
  { key: "dashboardAccess", name: "Dashboard Access" },
  // { key: "creatorsFunnel", name: "Creators Funnel" },
  { key: "bytescareRequests", name: "Bytescare Requests" },
  { key: "migration", name: "Migration" },
  { key: "whatsappManagement", name: "Whatsapp Management" },
  { key: "refernEarnRequests", name: "Refern Earn Requests" },
  { key: "refernEarnEarnings", name: "Refern Earn Earnings" },
  { key: "landingPageTemplates", name: "Landing Page Templates" },
];
