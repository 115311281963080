import "./landingPageTemplates.css";

import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Input,
  message,
  Modal,
  Table,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from "antd";

import {
  CloseOutlined,
  EditOutlined,
  FileJpgOutlined,
  FileSyncOutlined,
  TabletOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import {
  createLandingPageTemplate,
  editLandingPageTemplate,
  getLandingPagesTemplates,
  uploadImage,
} from "../api";

const LandingPageTemplates = () => {
  const [templates, setTemplates] = useState();
  const [formVisible, setFormVisible] = useState(false);
  const [selectedTempleteData, setSelectedTempleteData] = useState(null);
  const [modelUpdatedData, setModelUpdatedData] = useState({
    available: { landingPageBuilder: true, mangoPageBuilder: true },
  });
  const [file, setFile] = useState(null);
  const [reloadCount, setReloadCount] = useState(1);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleSyncTemplate = async (templateId) => {
    try {
      setTemplateLoading(true);
      const resp = await editLandingPageTemplate({
        landingPageId: templateId,
        syncTemplate: true,
      });
      if (resp?.code === 0) {
        message.success("template sync successfully");
        setReloadCount(reloadCount + 1);
      }
    } catch (error) {
      message.error(
        error?.data?.message || "Something went wrong with sync template"
      );
    } finally {
      setTemplateLoading(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (value, record) => {
        return (
          <div className="templateName">
            <Typography.Title level={5} style={{ margin: "0 10px 0 0" }}>
              {value}{" "}
            </Typography.Title>
            {record?.isPremium ? <Tag color="#ffc233">Premium</Tag> : null}
          </div>
        );
      },
    },
    {
      title: "Available for\n(MangoPage, LandingPage)",
      dataIndex: "available",
      key: "available",
      width: 150,
      align: "center",
      render: (_, record) => {
        return (
          <div className="availableWrapper">
            <Checkbox checked={record?.available?.landingPageBuilder} />
            <Checkbox checked={record?.available?.mangoPageBuilder} />
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width: 300,
      align: "center",
      render: (_, record) => {
        return (
          <div className="actionwrapper">
            <div className="actionItem">
              <Tooltip title="HTML page view">
                <Button
                  type="primary"
                  icon={<TabletOutlined />}
                  onClick={() => {
                    if (record?.previewUrl) {
                      window.open(record?.previewUrl);
                    } else {
                      message.warning("File not available");
                    }
                  }}
                />
              </Tooltip>
            </div>
            <div className="actionItem">
              <Tooltip title="Website preview img">
                <Button
                  type="primary"
                  icon={<FileJpgOutlined />}
                  onClick={() => {
                    if (record?.src) {
                      window.open(record?.src);
                    } else {
                      message.warning("File not available");
                    }
                  }}
                />
              </Tooltip>
            </div>
            <div className="actionItem">
              <Tooltip
                title={
                  record?.landingPage
                    ? "Edit template"
                    : "Edit templete diabled"
                }
              >
                <Button
                  type="primary"
                  disabled={!record?.landingPage}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedTempleteData(record);
                    setModelUpdatedData(record);
                    setFormVisible(true);
                  }}
                />
              </Tooltip>
            </div>
            <div className="actionItem">
              <Tooltip
                title={
                  record?.landingPage
                    ? "Sync Template"
                    : "Sync Template diabled"
                }
              >
                <Button
                  type="primary"
                  disabled={!record?.landingPage}
                  icon={<FileSyncOutlined />}
                  onClick={() => {
                    handleSyncTemplate(record?.landingPage);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const resetForm = () => {
    setFile(null);
    setModelUpdatedData({
      available: { landingPageBuilder: true, mangoPageBuilder: true },
    });
    setSelectedTempleteData(null);
  };

  const handleNewTemplate = async () => {
    try {
      if (!file) {
        message.error("Upload template preview image file");
        return;
      }
      setButtonLoader(true);
      const formData = new FormData();
      formData.append("image", file);
      const imgResp = await uploadImage(formData);
      if (imgResp?.code !== 0) {
        message.error("Something went wrong in upload image");
        return;
      }
      const reqBody = {
        ...modelUpdatedData,
        src: imgResp?.result?.files?.[0],
      };
      const resp = await createLandingPageTemplate(reqBody);
      if (resp?.code === 0) {
        setReloadCount(reloadCount + 1);
        resetForm();
        setFormVisible(false);
        message.success("New template created successfully");
      } else {
        console.log(resp);
      }
    } catch (error) {
      message.error("Something went wrong");
    } finally {
      setButtonLoader(false);
    }
  };

  const handleEditTemplate = async () => {
    try {
      if (!file && !modelUpdatedData?.src) {
        message.error("Upload template preview image file");
        return;
      }
      setButtonLoader(true);
      let imgResp = null;
      let reqBody = {
        available: modelUpdatedData?.available,
        name: modelUpdatedData?.name,
        landingPageId: modelUpdatedData?.landingPage,
        isPremium: modelUpdatedData?.isPremium,
        src: modelUpdatedData?.src,
      };
      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        imgResp = await uploadImage(formData);
        if (imgResp?.code !== 0) {
          message.error("Something went wrong in upload image");
          return;
        }
        reqBody = {
          ...reqBody,
          src: imgResp?.result?.files?.[0],
        };
      }
      const resp = await editLandingPageTemplate(reqBody);
      if (resp?.code === 0) {
        message.success("Template edited successfully");
        setReloadCount(reloadCount + 1);
        setFormVisible(false);
        resetForm();
      } else {
        console.log(resp);
      }
    } catch (error) {
      message.error("Something went wrong");
    } finally {
      setButtonLoader(false);
    }
  };

  const getTempleteList = useCallback(async () => {
    try {
      setTemplateLoading(true);
      const resp = await getLandingPagesTemplates();
      if (resp?.code === 0) {
        setTemplates(resp?.result);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
    } finally {
      setTemplateLoading(false);
    }
  }, []);

  useEffect(() => {
    getTempleteList();
  }, [reloadCount]);

  return (
    <div className="landingPageTemplatesMainWrapper">
      <div className="titleCreateBtmWrapper">
        <Typography.Title level={3}>Landing Page Templates</Typography.Title>
        <Button onClick={() => setFormVisible(true)}>
          Create New Template
        </Button>
      </div>
      <div>
        <Table
          className="templateTable"
          dataSource={templates}
          columns={columns}
          loading={templateLoading}
          pagination={false}
        />
        ;
      </div>
      <Modal
        visible={formVisible}
        title={`${selectedTempleteData ? "Edit" : "Add"} Template`}
        onCancel={() => {
          setFormVisible(false);
          resetForm();
        }}
        okButtonProps={{ loading: buttonLoader }}
        destroyOnClose
        onOk={() => {
          if (!selectedTempleteData) {
            handleNewTemplate();
          } else {
            handleEditTemplate();
          }
        }}
      >
        <div className="modalWrapper">
          <div className="modalItemWrapper">
            Template Id:
            <Input
              value={modelUpdatedData?._id}
              disabled={selectedTempleteData}
              onChange={(e) =>
                setModelUpdatedData((prev) => ({
                  ...prev,
                  landingPageId: e?.target?.value,
                }))
              }
            />
          </div>
          <div className="modalItemWrapper">
            Template Name:
            <Input
              value={modelUpdatedData?.name}
              onChange={(e) =>
                setModelUpdatedData((prev) => ({
                  ...prev,
                  name: e?.target?.value,
                }))
              }
            />
          </div>
          <div className="modalItemWrapper">
            Template Available for:
            <br />
            <Checkbox
              checked={modelUpdatedData?.available?.landingPageBuilder}
              onChange={(e) =>
                setModelUpdatedData((prev) => {
                  const { available, ...remaining } = prev;
                  return {
                    ...remaining,
                    available: {
                      ...available,
                      landingPageBuilder: e?.target?.checked,
                    },
                  };
                })
              }
            >
              Landing Page Builder
            </Checkbox>
            <Checkbox
              checked={modelUpdatedData?.available?.mangoPageBuilder}
              onChange={(e) =>
                setModelUpdatedData((prev) => {
                  const { available, ...remaining } = prev;
                  return {
                    ...remaining,
                    available: {
                      ...available,
                      mangoPageBuilder: e?.target?.checked,
                    },
                  };
                })
              }
            >
              Mango Page Builder
            </Checkbox>
          </div>
          <div className="modalItemWrapper">
            Is Premium template:{" "}
            <Checkbox
              checked={modelUpdatedData?.isPremium}
              onChange={(e) =>
                setModelUpdatedData((prev) => ({
                  ...prev,
                  isPremium: e.target?.checked,
                }))
              }
            />
          </div>
          <div className="modalItemWrapper">
            Upload preview template image file
            <br />
            {file || modelUpdatedData?.src ? (
              <div className="uploadedFileName">
                <p>{file?.name || modelUpdatedData?.src}</p>
                <CloseOutlined
                  onClick={() => {
                    if (file) {
                      setFile(null);
                    } else {
                      setModelUpdatedData((prev) => {
                        const { src, ...remaining } = prev;
                        return remaining;
                      });
                    }
                  }}
                />
              </div>
            ) : (
              <Upload
                accept={[".png", ".jpg", ".jpeg"]}
                beforeUpload={(img) => setFile(img)}
                multiple={false}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LandingPageTemplates;
